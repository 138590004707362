import React from 'react';

export default function CheckBox() {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.52051 2.76501H20.5205C21.6251 2.76501 22.5205 3.66045 22.5205 4.76501V20.765C22.5205 21.8696 21.6251 22.765 20.5205 22.765H4.52051C3.41594 22.765 2.52051 21.8696 2.52051 20.765V4.76502C2.52051 3.66045 3.41594 2.76501 4.52051 2.76501ZM0.520508 4.76502C0.520508 2.55588 2.31137 0.765015 4.52051 0.765015H20.5205C22.7296 0.765015 24.5205 2.55588 24.5205 4.76501V20.765C24.5205 22.9742 22.7296 24.765 20.5205 24.765H4.52051C2.31137 24.765 0.520508 22.9742 0.520508 20.765V4.76502ZM17.5609 10.4721C17.9515 10.0816 17.9515 9.44843 17.5609 9.05791C17.1704 8.66738 16.5373 8.66738 16.1467 9.05791L10.4372 14.7675L8.22761 12.5579C7.83709 12.1674 7.20393 12.1674 6.8134 12.5579C6.42288 12.9484 6.42288 13.5816 6.8134 13.9721L9.73007 16.8888C10.1206 17.2793 10.7538 17.2793 11.1443 16.8888L17.5609 10.4721Z"
        fill="#F15E32"
      />
    </svg>
  );
}
