import React from 'react';
import { RichText } from 'prismic-reactjs';

import { randomId } from '../../../unique-id-generator';
import Directions from './directions';
import Steps from './steps';

const getComponentByContentType = (steps, showNutritionInfo, font) => {
  switch (steps.contentType) {
    case 'steps':
      return (
        <div className="recipes-steps-outermost " id={font?.paragraph}>
          <h1 id={font?.heading5}>Ingredients</h1>
          {steps.items.map((step) => {
            return <Steps steps={step} />;
          })}
        </div>
      );
    case 'recipe_description':
      return (
        <div className="recipe-description-Container" id={font?.paragraph}>
          {RichText.render(steps.primary.recipeDescription.raw)}
        </div>
      );

    case 'nutritional_value':
      return (
        <>
          {showNutritionInfo ? (
            <div className="recipe-nutrition-value-Container" id={font?.paragraph}>
              <h3 id={font?.heading5}>Nutrition Facts</h3>
              {RichText.render(steps.primary.nutritionalValue.raw)}
            </div>
          ) : (
            ''
          )}
        </>
      );

    case 'stats':
      return (
        <div className="recipe-stats-outermost">
          <h3 id={font?.heading5}>Stats</h3>
          <div className="recipe-stats-Container">
            <div className="prep-time">
              <h6 id={font?.paragraph}>Prep</h6>
              {RichText.render(steps.primary.prep.raw)}
            </div>
            <div className="cook">
              <h6 id={font?.paragraph}>Cook</h6>
              {RichText.render(steps.primary.cook.raw)}
            </div>
            <div className="additional">
              <h6 id={font?.paragraph}>Additional</h6>
              {RichText.render(steps.primary.additional.raw)}
            </div>
            <div className="total">
              <h6 id={font?.paragraph}>total</h6>
              {RichText.render(steps.primary.total.raw)}
            </div>
            <div className="servings">
              <h6 id={font?.paragraph}>Servings</h6>
              {RichText.render(steps.primary.servings.raw)}
            </div>
            <div className="yield">
              <h6 id={font?.paragraph}>Yeild</h6>
              {RichText.render(steps.primary.yield.raw)}
            </div>
          </div>
        </div>
      );

    case 'directions':
      return (
        <div className="recipes-direction-container " id={font?.paragraph}>
          <h3 id={font?.heading5}>Directions</h3>
          {steps.items.map((step, idx) => {
            return (
              <div key={() => randomId()} className="recipes-directions-slice-wrapper ">
                <Directions step={step} index={idx} font={font} />
              </div>
            );
          })}
        </div>
      );

    default:
      return null;
  }
};

const RecipesSteps = ({ steps, showNutritionInfo, font }) => {
  const stepsArray = steps;
  return stepsArray.map((steps) => getComponentByContentType(steps, showNutritionInfo, font));
};

export default RecipesSteps;
