import React, { useState, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

import CloseIcon from '@assets/browse-books/icon-close.svg';
import RadioChecked from '@assets/recipes-print-modal/radio-checked.svg';
import Radio from '@assets/recipes-print-modal/radio.svg';
import CheckBoxEmpty from '@assets/recepies/check-box-empty';
import CheckBox from '@assets/recepies/check-box';
import BlogButton from '@components/reusable-buttons/blog-button';
import { RecipesModalDataClass } from './recipe-class-data';

const RecipePrintModal = ({
  recepiesInfo,
  recipesSteps,
  closeFunction,
  showStatus,
  relatedAuthor,
  setShowBackdrop,
  showBackdrop,
}) => {
  const [includeImage, setIncludeImage] = useState(true);
  const [showCheckBoxImage, setShowCheckBox] = useState(false);
  const [showEmptyBoxImage, setShowEmptyBox] = useState(true);
  const [showCheckBoxNutrition, setShowCheckBoxNutrition] = useState(false);
  const [showEmptyBoxNutrition, setShowEmptyBoxNutrition] = useState(true);
  const [showNutritionInfo, setShowNutritionInfo] = useState(true);

  const [showRadio, setShowRadio] = useState({
    small: true,
    medium: false,
    large: false,
  });
  const [font, setFont] = useState({
    heading1: 'smallheading',
    heading5: 'smallh5',
    paragraph: 'smallpara',
  });

  const ref = React.createRef();

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div className="recipe-modal-container">
      <div className="recipe-print-modal-heading">
        <h3>Preview and print</h3>
        <div
          className="recipe-modal-close-icon-container"
          onClick={() => {
            closeFunction(!showStatus);
            setShowBackdrop(!setShowBackdrop);
          }}
        >
          <img src={CloseIcon} />
        </div>
      </div>
      <div className="recipe-modal-settings">
        <div className="recipe-modal-font half-width">
          <h5>Font size</h5>
          <div className="radio-container-recipe">
            <span className={showRadio.small ? 'show radio-button' : 'hide'}>
              <img src={RadioChecked} />
            </span>
            <span
              onClick={() => {
                setShowRadio({
                  ...false,
                  small: true,
                });
                setFont({
                  heading1: 'smallheading',
                  heading5: 'smallh5',
                  paragraph: 'smallpara',
                });
              }}
              className={!showRadio.small ? 'show radio-button' : 'hide'}
            >
              <img src={Radio} />
            </span>
            <h6 className="recipe-modal-button-text">Small</h6>
            <span className={showRadio.medium ? 'show radio-button' : 'hide'}>
              <img src={RadioChecked} />
            </span>
            <span
              onClick={() => {
                setShowRadio({
                  ...false,
                  medium: true,
                });
                setFont({
                  heading1: 'mediumheading',
                  heading5: 'mediumh5',
                  paragraph: 'mediumpara',
                });
              }}
              className={!showRadio.medium ? 'show radio-button' : 'hide'}
            >
              <img src={Radio} />
            </span>
            <h6 className="recipe-modal-button-text">Medium</h6>
            <span className={showRadio.large ? 'show radio-button' : 'hide'}>
              <img src={RadioChecked} />
            </span>
            <span
              onClick={() => {
                setShowRadio({
                  ...false,
                  large: true,
                });
                setFont({
                  heading1: 'largeheading',
                  heading5: 'largeh5',
                  paragraph: 'largepara',
                });
              }}
              className={!showRadio.large ? 'show radio-button' : 'hide'}
            >
              <img src={Radio} />
            </span>
            <h6 className="recipe-modal-button-text">Large</h6>
          </div>
        </div>
        <div className="recipe-modal-image half-width">
          <h5>Include</h5>
          <div className="radio-container-recipe">
            <span
              className={showEmptyBoxImage ? 'show empty-box' : 'hide'}
              onClick={() => {
                setIncludeImage(!includeImage);
                setShowCheckBox(!showCheckBoxImage);
                setShowEmptyBox(!showEmptyBoxImage);
              }}
            >
              <CheckBox />
            </span>
            <span
              className={showCheckBoxImage ? 'show check-box' : 'hide'}
              onClick={() => {
                setIncludeImage(!includeImage);
                setShowCheckBox(!showCheckBoxImage);
                setShowEmptyBox(!showEmptyBoxImage);
              }}
            >
              <CheckBoxEmpty />
            </span>

            <h6 className="recipe-modal-button-text">Images</h6>
            <span
              className={showCheckBoxNutrition ? 'show check-box' : 'hide'}
              onClick={() => {
                setShowNutritionInfo(!showNutritionInfo);
                setShowCheckBoxNutrition(!showCheckBoxNutrition);
                setShowEmptyBoxNutrition(!showEmptyBoxNutrition);
              }}
            >
              <CheckBoxEmpty />
            </span>
            <span
              className={showEmptyBoxNutrition ? 'show empty-box' : 'hide'}
              onClick={() => {
                setShowNutritionInfo(!showNutritionInfo);
                setShowCheckBoxNutrition(!showCheckBoxNutrition);
                setShowEmptyBoxNutrition(!showEmptyBoxNutrition);
              }}
            >
              <CheckBox />
            </span>
            <h6 className="recipe-modal-button-text">Nutrition Info</h6>
          </div>
        </div>
      </div>
      <div className="recipe-modal-content-container">
        <div className="recipe-modal-content-inner">
          <RecipesModalDataClass
            recepiesInfo={recepiesInfo}
            recipesSteps={recipesSteps}
            relatedAuthor={relatedAuthor}
            includeImage={includeImage}
            showNutritionInfo={showNutritionInfo}
            font={font}
            ref={componentRef}
          />
        </div>
      </div>
      <div className="recipe-modal-button-holder">
        <div className="recipe-print-button-holder" onClick={handlePrint}>
          <BlogButton buttonText="Print or Save" />
        </div>
      </div>
    </div>
  );
};

export default RecipePrintModal;
