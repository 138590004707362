import React from 'react';
import { Link } from 'gatsby';

const RelatedRecepies = ({ relatedRecipes }) => {
  if (!relatedRecipes) {
    return <></>;
  }

  return (
    <div className="related-recepies-container common-container">
      <h2>Related recepies</h2>
      <div className="related-recepies-outer">
        {relatedRecipes.map((item) => {
          if (!item.relatedRecipe.document) {
            return <></>;
          }
          const { uid } = item.relatedRecipe;
          const { featuredImage, tags, excerpt, title } = item.relatedRecipe.document.data;
          return (
            <div className="related-recepies">
              <div className="related-recepies-image-holder">
                <Link to={`/blog/${uid}`}>
                  <img src={featuredImage?.fluid.src} alt="recepies- item" />{' '}
                </Link>
              </div>
              <div className="related-recepes-tag subtitle2">{tags.text}</div>
              <h4 className="related-recepies-title">{title.text}</h4>
              <p className="related-recepies-desc body1">{excerpt.text}</p>
              <Link to={`/blog/${uid}`}>
                <div className="related-recepies-readmore-botton subtitle2">Read more</div>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RelatedRecepies;
