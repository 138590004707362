import React, { useState } from 'react';

import Text from './text';
import CheckBox from '@assets/recepies/check-box';
import CheckBoxEmpty from '@assets/recepies/check-box-empty';
const Steps = ({ steps }) => {
  const [showCheckBox, setShowCheckBox] = useState(false);
  const [showEmptyCheckBox, setShowEmptyCheckBox] = useState(true);
  return (
    <div className="steps-and-checkbox">
      <div className="recipes-steps-slice-wrapper ">
        <div
          className="check-box-holder-steps"
          className={showCheckBox ? 'check-box-holder-steps' : 'hide'}
          onClick={() => {
            setShowCheckBox(!showCheckBox);
            setShowEmptyCheckBox(!showEmptyCheckBox);
          }}
        >
          <CheckBox />
        </div>
        <div
          className="check-empty-box-holder-steps"
          className={showEmptyCheckBox ? 'check-empty-box-holder-steps' : 'hide'}
          onClick={() => {
            setShowEmptyCheckBox(!showEmptyCheckBox);
            setShowCheckBox(!showCheckBox);
          }}
        >
          <CheckBoxEmpty />
        </div>
        <div className={showCheckBox ? 'opacity-low' : 'steps-text-container'}>
          <Text step={steps} />
        </div>
      </div>
    </div>
  );
};
export default Steps;
