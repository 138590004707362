import React from 'react';

import RecipesSteps from '../recepies-details/slices/slices';

export class RecipesModalDataClass extends React.Component {
  render() {
    const { recepiesInfo, relatedAuthor, recipesSteps, includeImage, showNutritionInfo, font } = this.props;
    if (!recepiesInfo) {
      return <></>;
    }

    const { excerpt, tags, title, postedOn, featuredImage } = recepiesInfo;
    const authorName = relatedAuthor?.document?.data.title?.text;
    return (
      <div className=" recipe-modal-data">
        {includeImage ? (
          <div className="blog-details-holder__image ">
            <img src={featuredImage?.fluid.src} alt="opening book" />
          </div>
        ) : null}

        <div className="blog-details-holder__description recipe-details-description">
          <div className="blog-details-container">
            <div className="blog-details__heading">
              <h1 id={font.heading1}>{title.text} </h1>
            </div>
            <div className="blog-details__summary" id={font.paragraph}>
              {excerpt?.text}
            </div>
            <div className="blog-details__info">
              <div className="blog-details-author">
                <h5 id={font.heading5}>By: {authorName}</h5>
              </div>
              <div className="posted-date-category">
                <div className="blog-details-posted-date" id={font.paragraph}>
                  {postedOn}
                </div>
                <div className="blog-details-category" id={font.paragraph}>
                  {tags.text}
                </div>
              </div>
            </div>
          </div>
          <div className="blog-details-body  u-margin-top-xs">
            <div className="recipe-rich-text-holder" id={includeImage ? '' : 'exclude-image'}>
              <RecipesSteps steps={recipesSteps} showNutritionInfo={showNutritionInfo} font={font} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
