import React from 'react';
import { graphql } from 'gatsby';

import Layout from '@layouts';
import RecepiesHero from '@components/recepies-details/recepies-details-hero';
import RelatedRecepies from '@components/recepies-details/related-recepies';
import Seo from '@layouts/common/seo';

const RecepiesDetailsPage = ({ data }) => {
  const recepiesData = data.prismicRecipes.data;
  const recipesSteps = recepiesData?.body;
  const relatedAuthor = recepiesData.relatedAuthor;
  const relatedRecipes = recepiesData.relatedRecipes;
  const title = recepiesData?.title?.text;
  const seoImage = recepiesData?.featuredImage?.fluid?.src;
  const description = recepiesData?.excerpt?.text;
  const uid = data.prismicRecipes?.uid;
  return (
    <>
      <Seo
        title={title}
        description={description}
        seoImage={seoImage}
        siteUrl={process.env.CLIENT_URL + 'blog/' + uid}
      />
      <Layout>
        <RecepiesHero recepiesInfo={recepiesData} relatedAuthor={relatedAuthor} recipesSteps={recipesSteps} />
        <RelatedRecepies relatedRecipes={relatedRecipes} />
      </Layout>
    </>
  );
};

export default RecepiesDetailsPage;

export const query = graphql`
  query recepiesQuery($id: String) {
    prismicRecipes(id: { eq: $id }) {
      uid
      data {
        body {
          ... on PrismicRecipesBodySteps {
            id
            primary {
              name {
                raw
              }
            }
            contentType: slice_type
            items {
              step_name {
                raw
              }
            }
          }
          ... on PrismicRecipesBodyRecipeDescription {
            id
            primary {
              recipeDescription: recipe_description {
                raw
              }
            }
            contentType: slice_type
          }
          ... on PrismicRecipesBodyStats {
            id
            contentType: slice_type
            primary {
              additional {
                raw
              }
              cook {
                raw
              }
              prep {
                raw
              }
              servings {
                raw
              }
              total {
                raw
              }
              yield {
                raw
              }
            }
          }
          ... on PrismicRecipesBodyNutritionalValue {
            id
            contentType: slice_type
            primary {
              nutritionalValue: nutritional_value {
                raw
              }
            }
          }
          ... on PrismicRecipesBodyDirections {
            id
            primary {
              stepNumber: step_number {
                raw
              }
            }
            contentType: slice_type
            items {
              directionStep: direction_step {
                raw
              }
            }
          }
        }
        excerpt {
          text
        }
        tags {
          text
        }
        featuredImage: featured_image {
          fluid {
            src
          }
        }
        postedOn: posted_on(fromNow: true)
        title {
          text
        }
        relatedRecipes: related_recipes {
          relatedRecipe: related_recipe {
            uid
            document {
              ... on PrismicRecipes {
                id
                data {
                  excerpt {
                    text
                  }
                  featuredImage: featured_image {
                    fluid {
                      src
                    }
                  }
                  postedOn: posted_on(fromNow: true)
                  tags {
                    text
                  }
                  title {
                    text
                  }
                }
              }
            }
          }
        }
        relatedAuthor: related_author {
          document {
            ... on PrismicAuthor {
              id
              data {
                title {
                  text
                }
              }
            }
          }
        }
      }
    }
  }
`;
