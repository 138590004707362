import React from 'react';

const ScrollTracker = () => {
  return (
    <div className="scroll-tracker-container">
      <div className="expanding-scroll-indicator" id="indicator"></div>
    </div>
  );
};

export default ScrollTracker;
