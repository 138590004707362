import React, { useState } from 'react';
import { RichText } from 'prismic-reactjs';

import StepTick from '@assets/recepies/step-tick';
import PassiveTick from '@assets/recepies/passive-tick';

export default function Directions({ step, index, font }) {
  const [showCheckbox, setShowCheckBox] = useState(false);
  const [showPassiveCheckbox, setShowPassiveCheckBox] = useState(true);
  return (
    <div className={showPassiveCheckbox ? 'recipes-direcions-text body1' : 'recipes-direcions-text body1 '}>
      <div
        className={showPassiveCheckbox ? 'show cursor-pointer' : 'hide'}
        onClick={() => {
          setShowCheckBox(!showCheckbox);
          setShowPassiveCheckBox(!showPassiveCheckbox);
        }}
      >
        <div className="tick-margin">
          <PassiveTick />
        </div>
      </div>
      <div
        className={showCheckbox ? 'show cursor-pointer' : 'hide'}
        onClick={() => {
          setShowCheckBox(!showCheckbox);
          setShowPassiveCheckBox(!showPassiveCheckbox);
        }}
      >
        <div className="tick-margin">
          <StepTick />
        </div>
      </div>
      <span className={showPassiveCheckbox ? 'normal' : 'opacity-low normal'}>
        <h4 id={font?.heading5}>Step {index + 1}</h4>
        {RichText.render(step.directionStep.raw)}
      </span>
    </div>
  );
}
