import { graphql, useStaticQuery, Link } from 'gatsby';
import React, { useState, useEffect } from 'react';

import RightArrow from '@assets/blog-category/right-arrow';
import CopyLink from '@assets/social-icons/copy-link';
import Facebook from '@assets/social-icons/facebook';
import Twitter from '@assets/social-icons/twitter';
import Linkedin from '@assets/social-icons/linkedin';
import PrintIcon from '@assets/recepies/print';
import ScrollTracker from '../scroll-tracker/scroll-tracker';
import RecipesSteps from './slices/slices';
import RecipePrintModal from '../recipe-print-modal/recipe-print-modal';
import BlogButton from '@components/reusable-buttons/blog-button';
import PrinterIcon from '@assets/recepies/printer-icon.svg';
import Backdrop from '@components/backdrop/backdrop';

const RecepiesHero = ({ recepiesInfo, relatedAuthor, recipesSteps }) => {
  if (!recepiesInfo) {
    return <></>;
  }
  const recipesStepsData = useStaticQuery(graphql`
    query RecipesSteps {
      allPrismicRecipes {
        nodes {
          data {
            body {
              ... on PrismicRecipesBodySteps {
                id
                slice_type
                items {
                  step_name {
                    raw
                  }
                }
              }
              ... on PrismicRecipesBodyStats {
                id
                slice_type
                primary {
                  additional {
                    raw
                  }
                  cook {
                    raw
                  }
                }
              }
              ... on PrismicRecipesBodyRecipeDescription {
                id
                slice_type
                primary {
                  recipe_description {
                    raw
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const [showPrintModal, setShowPrintModal] = useState(false);
  const [showNutritionInfo, setShow] = useState(true);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const { excerpt, tags, title, postedOn, featuredImage } = recepiesInfo;
  const authorName = relatedAuthor?.document?.data.title?.text;

  let totalHeight;
  let winHeight;
  let diff;
  let percentScrolled = 1;
  let totalDiff;
  const handleTracker = () => {
    percentScrolled = Math.floor((window.scrollY / totalDiff) * 100);

    document.getElementById('indicator').style.width = `${percentScrolled}vw`;
  };

  useEffect(() => {
    totalHeight = document.body.offsetHeight;
    winHeight = window.innerHeight;
    diff = totalHeight - winHeight;
    const footer = document.getElementById('footer-body');
    totalDiff = diff - footer.offsetHeight;

    window.addEventListener('scroll', handleTracker);

    return () => {
      window.removeEventListener('scroll', handleTracker);
    };
  }, [handleTracker]);
  return (
    <div className="blog-details-holder common-container u-margin-bottom-md u-margin-top-md ">
      <ScrollTracker />
      <div className="blog-details-holder__image ">
        <img src={featuredImage?.fluid.src} alt="opening book" />
      </div>

      <div className="blog-details-holder__description recipe-details-description">
        <div className="blog-details-container">
          <div className="blog-details-holder__nested-button">
            <div className="blog-details-option">
              <h6 className="low-opacity">Home</h6>
              <span className="blog-details-arrow">
                <RightArrow />
              </span>
            </div>
            <div className="blog-details-option">
              <h6 className="low-opacity">Knowledge</h6>
              <span className="blog-details-arrow">
                <RightArrow />
              </span>
            </div>
            <div className="blog-details-option">
              <h6 className="bold">Recepies Details</h6>
            </div>
          </div>
          <div className="blog-details__heading">
            <h1>{title.text} </h1>
          </div>
          <div className="blog-details__summary">{excerpt?.text}</div>
          <div className="blog-details__info">
            <div className="blog-details-author">
              <h5>By: {authorName}</h5>
            </div>
            <div className="posted-date-category">
              <div className="blog-details-posted-date">{postedOn}</div>
              <div className="blog-details-category">{tags.text}</div>
            </div>
          </div>
        </div>
        <div className="blog-details-body  u-margin-top-xs">
          <div className="recipe-rich-text-holder">
            <RecipesSteps steps={recipesSteps} showNutritionInfo={showNutritionInfo} />
          </div>

          <div className="blog-details-share-to">
            <div className="share-to-options">
              <h5>Share to</h5>
            </div>
            <div className="share-to-options">
              <CopyLink />
              <div className="social-option">Copy Link</div>
            </div>
            <a href="https://www.facebook.com/">
              <div className="share-to-options">
                <Facebook color="black" />
                <div className="social-option">Facebook</div>
              </div>
            </a>
            <a href="https://www.twitter.com/">
              <div className="share-to-options">
                <Twitter color="black" /> <div className="social-option">Twitter</div>
              </div>
            </a>
            <a href="https://www.linkedin.com/">
              <div className="share-to-options">
                <Linkedin color="black" /> <div className="social-option">Linkedin</div>
              </div>
            </a>
            <div className="share-to-line">
              <div
                className=" share-to-options"
                id="share-to-print"
                onClick={() => {
                  setShowPrintModal(!showPrintModal);
                  setShowBackdrop(!showBackdrop);
                }}
              >
                <PrintIcon />
                <div className="social-option" id="print-text">
                  Print
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="recipe-print-button-container-tab"
        onClick={() => {
          setShowPrintModal(!showPrintModal);
          setShowBackdrop(!showBackdrop);
        }}
      >
        <BlogButton buttonText="Print This Recipe" print />
      </div>

      <div className={showPrintModal ? 'show recipe-modal-outermost' : 'hide'}>
        <RecipePrintModal
          recepiesInfo={recepiesInfo}
          recipesSteps={recipesSteps}
          relatedAuthor={relatedAuthor}
          closeFunction={setShowPrintModal}
          showStatus={showPrintModal}
          setShowBackdrop={setShowBackdrop}
          showBackdrop={showBackdrop}
        />
      </div>
      <div
        className={showBackdrop ? 'show' : 'hide'}
        onClick={() => {
          setShowPrintModal(!showPrintModal);
          setShowBackdrop(!showBackdrop);
        }}
      >
        <Backdrop />
      </div>
    </div>
  );
};

export default RecepiesHero;
